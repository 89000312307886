const data = {
  interviews: [
    {
      title: 'How to Go From Bootcamp Grad to Senior Engineer',
      source: 'Develop Yourself Podcast',
      url: 'https://open.spotify.com/episode/5e9MlP1JhFw492rIMYUpNF?si=1f78955d5bb74341',
      date: 'November 20, 2021',
    },
    {
      title: 'How Peter Elbaum tripled his salary by learning to code',
      source: 'No CS Degree',
      url: 'https://www.nocsdegree.com/how-this-person-tripled-their-salary-by-learning-to-code/',
      date: 'March 26, 2020',
    }
  ]
}

export default data 