import React from "react"
import { Link, graphql } from "gatsby"

import profilePic from "../../content/assets/profile-pig.jpg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import newsletterData from "../../data/newsletters"
import interviewData from "../../data/interviews"

class Index extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Peter Elbaum | Software Engineer, YouTuber, Writer" />
        <div className="container">
          <div className="flex intro">
            <h1 className="intro-text">Hi, I'm Peter</h1>
            <img className="index-profile" src={profilePic}></img>
          </div>
          <p className="description">I'm a software engineer from North Carolina. I'm passionate about <Link to="/work">coding</Link>, <a href="/articles">writing</a>, and sharing what I know. Welcome to my home on the internet!</p>
          <p className="description">On this site I write about code, self-improvement, entrepreneurship, personal finance, and more. For videos on those topics, check out <a rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/channel/UCPcyffOFc7YGyDgqmvZJs7Q">my YouTube channel</a>.</p>
          <p className="description">If you're an inquisitive person, you should join my newsletter, <Link to="/newsletter">Elbaum's World</Link>. It’s one email a month with updates from my path to sustainable self-employment, plus links to my new articles, tweets, videos and other favorites.</p>
          <p className="description"><Link to="/about">Find out more about me here →</Link></p>
          {/* <Subscribe hideExplainer /> */}
        </div>
        {/* Article Seciont */}
        <h2>Read the latest articles:</h2>
        { posts.map((post, i) => {
          const title = post.node.frontmatter.title || post.node.fields.slug
          return i < 3 ?
          <article key={post.node.fields.slug}>
            <header>
              <h3 className="blog-title">
                <Link style={{ boxShadow: `none` }} to={post.node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <p>{post.node.frontmatter.date}</p>
            </header>
          </article>
          : null
        })}
        <Link to="/articles"><div className="button">View All</div></Link>
        {/*  */}
        <h2>Browse the latest newsletter:</h2>
        { newsletterData.newsletters.map((post, i) => {
          const title = post.title
          return i < 3 ?
            <article key={i}>
              <header>
                <h3 className="blog-title">
                  <a rel="noopener noreferrer" target="_blank" style={{ boxShadow: `none` }} href={post.link}>
                    {title}
                  </a>
                </h3>
                <p>{post.date}</p>
              </header>
            </article>
            : null
        })}
        <Link to="/newsletter"><div className="button">View All</div></Link>
        <h2>Recent appearances:</h2>
        { interviewData.interviews.map((interview, i) => {
          return (
            <article key={i}>
              <header>
                <h3 className="blog-title">
                  <a rel="noopener noreferrer" target="_blank" style={{ boxShadow: `none` }} href={interview.url}>
                    {interview.title}
                  </a>
                </h3>
                <p>{interview.source}</p>
              </header>
            </article>
          )
        })}
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
          }
        }
      }
    }
  }
`
